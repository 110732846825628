



























import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FollowButton from '@/components/buttons/FollowButton.vue';
import EntityType from '@/utils/enums/EntityType';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: {
    FollowButton,
  },
})
/* eslint-disable no-underscore-dangle */
export default class ChannelCard extends Vue {
  @Prop({ required: true })
  private uid!: string;

  @Prop({ required: true })
  private name!: string;

  @Prop({ required: true })
  private imageFileResource!: FileResource;

  @Prop({ required: true })
  private _followerCount!: number;

  @Prop({ required: false, default: null })
  private _isFollowed!: string | null;

  @Prop({ required: false, default: 1 })
  private size!: number;

  private entityType = EntityType.CHANNEL;

  private get channelImage(): string {
    if (this.imageFileResource) {
      return FileResourceHelper.getFullPath(this.imageFileResource, 'w40');
    }
    return '/img/banner/empty.svg';
  }

  private get followerSubtitle(): TranslateResult | string {
    if (this._followerCount === 1) {
      return `${this._followerCount} ${this.$t('app.feed-page.follower')}`;
    }
    return `${this._followerCount} ${this.$t('app.feed-page.followers')}`;
  }

  private onClick(): void {
    this.$router.push({ name: 'channel-detail', params: { channelId: this.uid } });
  }
}
